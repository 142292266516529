import React, { useState, useRef } from "react"



import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from 'swiper';

import { StyledTeamSection } from "./StyledTeamSection"
import Container from "../../Container"
import SectionTitle from "../../atoms/SectionTitle"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const TeamSection = props => {
  const [value, setValue] = useState(0)

  function onChange(value, name) {
    setValue(value)
  }
  const data = props.data;


  const pagination = {
    clickable: true,
    dynamicBullets: true,
  };



  const prevRef = useRef<HTMLDivElement>(null)
  const nextRef = useRef<HTMLDivElement>(null)

  const ClientsItems = data.map((item, index) => {
    let PersonImage = ""
    let PesronName = item.title
    if (item.featuredImage) {
      PersonImage = (
        <GatsbyImage
        className="image image--right"
        image={getImage(item.featuredImage.node.localFile)}
        alt={item.featuredImage.node.altText}
        title={item.featuredImage.node.title}
        />
      )
    }

    return (
      <div className="person__single">
        {PersonImage}
        <div className="person__content">
          <h5 className="name">{item.title}</h5>
          <div className="position">{item.ACF_team.position}</div>
          <div
            className="desc"
            dangerouslySetInnerHTML={{ __html: item.content }}
          />
        </div>
      </div>
    )
  })

  const SwiperClientsItems = data.map((item, index) => {
    let PersonImage = ""
    let PesronName = item.title
    if (item.featuredImage) {
      PersonImage = (
        <GatsbyImage
        className="image image--right"
        image={getImage(item.featuredImage.node.localFile)}
        alt={item.featuredImage.node.altText}
        title={item.featuredImage.node.title}
        />
      )
    }

    return (
      <SwiperSlide className="slider" key={item.title}>
        {PersonImage}
        <div className="person__content">
          <h5 className="name">{item.title}</h5>
          <div className="position">{item.ACF_team.position}</div>
          <div
            className="desc"
            dangerouslySetInnerHTML={{ __html: item.content }}
          />
        </div>
      </SwiperSlide>
    )
  })

  let params = {
    slidesPerView: 1,
  }

  return (
    <StyledTeamSection>
      <Container>
        <SectionTitle text="napędzają_" textBold="nas" color="#000" />
      </Container>


      <Swiper
        className="mobileSwiper"
        {...params}
        centeredSlides={true}
        breakpoints={{
          // when window width is >= 640px
          640: {
            width: 640,
            slidesPerView: 1,
          },
          // when window width is >= 768px
          768: {
            width: 768,
            slidesPerView: 2,
            freeMode: true,
          },
          1368: {
            width: 1368,
            slidesPerView: 3,
            freeMode: true,
          },
          1568: {
            width: 1568,
            slidesPerView: 4,
            freeMode: true,
          },
        }}
        pagination={pagination} modules={[Pagination]}
      >
        {SwiperClientsItems}
      </Swiper>

      {props.children}

      <div>{props.about}</div>
    </StyledTeamSection>
  )
}
export default TeamSection
