import styled from "styled-components"

export const StyledTeamSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 6rem 0 0;
  position: relative;


  .GroupImage {
    margin: 1rem 0;

    @media ${props => props.theme.sizes.laptop} {
      margin: 6rem 0;
    }
  }

  .BrainhubCarousel__container {
    padding: 0 0 6rem;
    display: none;

    @media ${props => props.theme.sizes.laptop} {
      display: block;
      margin-top: -4rem;
    }
  }

  .person__content {
    display: none;
    flex-direction: column;
    font-family: ${props => props.theme.fonts.primary};

    .name {
      margin: 1.5rem 0 1rem;
      color: ${props => props.theme.colors.text};
    }
    .position {
      font-size: 1rem;
      padding-bottom: 0.65rem;
      margin-bottom: 0.65rem;
      position: relative;

      &:after {
        content: " ";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 3rem;
        height: 3px;
        background: ${props => props.theme.colors.text};
      }
    }
    .desc {
      font-size: 1rem;
      line-height: 24px;
    }
  }
  .BrainhubCarousel__arrows {
    position: absolute;
    background: none;
    z-index: 10;

    span {
      border-color: ${props => props.theme.colors.primary};
      border-width: 5px 5px 0 0;
      padding: 10px;

      @media ${props => props.theme.sizes.desktop} {
        border-width: 3px 3px 0 0;
        padding: 6px;
      }
    }
    &:hover {
      background: none;

      span {
        border-color: ${props => props.theme.colors.primary};
      }
    }
  }
  .BrainhubCarousel__arrows:hover:enabled {
    background-color: transparent;
  }
  .BrainhubCarousel__arrows:hover:enabled span  {
    border-color: rgb(241, 146, 6);
  }
  .BrainhubCarousel__arrowRight {
    right: 32%;
    bottom: 20%;

    @media ${props => props.theme.sizes.laptop} {
      right: 49%;
    }

    @media ${props => props.theme.sizes.laptopL} {
      right: 54%;
    }

    @media ${props => props.theme.sizes.desktop} {
      left: 37%;
      bottom: 46%;
    }
  }
  .BrainhubCarousel__arrowLeft {
    left: 9%;
    bottom: 20%;

    @media ${props => props.theme.sizes.laptop} {
      left: 7%;
    }

    @media ${props => props.theme.sizes.laptopL} {
      left: 7%;
    }

    @media ${props => props.theme.sizes.desktop} {
      left: 12rem;
      bottom: 46%;
    }
  }

  .BrainhubCarousel__track {
    margin-left: 7.5rem !important;

    @media ${props => props.theme.sizes.desktop} {
      margin-left: 12.5rem !important;
    }
  }
  .BrainhubCarouselItem--active {
    /* max-width: 100vw !important;
    width: 100vw !important; */
    min-height: 900px;

    .person__single {
    }

    .gatsby-image-wrapper {
      width: 100vw;
      margin-top: 9rem;
    }
    .person__content {
      display: flex;
    }
    @media ${props => props.theme.sizes.tablet} {
      max-width: 500px !important;
      width: 500px !important;
      margin: 0 2rem !important;
      z-index: 100;

      .gatsby-image-wrapper {
        width: 500px;
        margin-top: 9rem;
      }
    }
  }

  .swiper-button-prev {
    left: 5rem;
  }
  .swiper-button-next {
    left: 10rem;
  }

  .slider {
    @media ${props => props.theme.sizes.tablet} {
      display: none;
    }
    .person__content {
      display: flex;
    }
  }
  .mobileSwiper {
    max-width: 99vw !important;

    @media ${props => props.theme.sizes.tablet} {
      padding: 4rem 0;
    }
    .swiper-wrapper {
      @media ${props => props.theme.sizes.laptop} {
        margin-left: -20%;
      }
    }

    .swiper-pagination {
      bottom: 380px;
      border-color: rgb(241, 146, 6);
    }
    .swiper-pagination .swiper-pagination-bullet {
      border: 3px solid  rgb(241, 146, 6);
    }

    .swiper-slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      transform: scale(.85);
    }

    .person__content {
      margin: 0 1rem;
      max-width: 80vw !important;
      visibility: hidden;


      .desc {
        font-family: ${props => props.theme.fonts.secondary};
        font-weight: 300;
      }
    }
    .swiper-slide-active {
      transform: scale(1);

      .person__content {
        visibility: visible;
      }
      .gatsby-image-wrapper {
        border: 3px solid rgb(241, 146, 6);;
      }
    }
    .swiper-button-next {
      color: ${props => props.theme.colors.primary};
      bottom: 0;
      left: auto;
      margin-top: 10px;
      background: #fff;

      &:after {
        color: ${props => props.theme.colors.primary};
      }

      @media ${props => props.theme.sizes.mobileL} {
        margin-top: 70px;
      }

      @media ${props => props.theme.sizes.tablet} {
        right: 10%;
        margin-top: -15%;
      }
    }
    .swiper-button-prev {
      bottom: 0;
      margin-top: 10px;
      background: #fff;
      color: ${props => props.theme.colors.primary};
      &:after {
        color: ${props => props.theme.colors.primary};
      }

      @media ${props => props.theme.sizes.mobileL} {
        margin-top: 70px;
      }

      @media ${props => props.theme.sizes.tablet} {
        left: 10%;
        margin-top: -15%;
      }
    }
  }
`
