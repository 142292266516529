import React from "react"

import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const StyledTextSection = styled.section`
  padding-top: 6rem;

  .StyledTextSection__wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @media ${props => props.theme.sizes.laptopL} {
      flex-direction: row;
    }
  }
`
const HiddenText = styled.h1`
  display: none;
`
const StyledTextBlock = styled.div`
  display: flex;
  flex-direction: column;

  @media ${props => props.theme.sizes.laptopL} {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  &:nth-of-type(2n) {
    .TextBlock__wrapper {
      grid-area: 1 / 1 / 2 / 2;
    }
  }

  .TextBlock__image {
    width: 100%;

    .gatsby-image-wrapper {
      height: 100%;
    }
  }

  .TextBlock__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 5% 6%;
  }

  &.TextBlock {
    &--1,
    &--3 {
      /* background: ${props => props.theme.colors.primary}; */
    }
    &--2,
    &--4 {
      flex-direction: column;

      @media ${props => props.theme.sizes.laptopL} {
        flex-direction: row-reverse;
      }
      /* background: ${props => props.theme.colors.black}; */
    }

    @media ${props => props.theme.sizes.laptop} {
      &--1,
      &--4 {
        /* background: ${props => props.theme.colors.primary}; */
      }
      &--2,
      &--3 {
        /* background: ${props => props.theme.colors.black}; */
      }
    }

    p {
      color: black;
      font-family: ${props => props.theme.fonts.secondary};
      font-size: 1.2rem;
      font-weight: 300;

      .orange {
        font-weight: 700;
      }
    }
  }
`

const AboutTextSection = props => {
  return (
    <StyledTextSection>
      <div className="StyledTextSection__wrapper">
        {props.content.map((item, index) => (
          <StyledTextBlock
            key={index}
            className={"TextBlock TextBlock--" + (index + 1)}
          >
            <div className="TextBlock__image">
              <GatsbyImage
                image={getImage(item.imageblock.localFile)}
                alt={item.imageblock.altText}
                title={item.imageblock.title}
              />
            </div>
            <div
              className="TextBlock__wrapper"
              dangerouslySetInnerHTML={{ __html: item.textblok }}
            ></div>
          </StyledTextBlock>
        ))}
      </div>
    </StyledTextSection>
  )
}
export default AboutTextSection
