import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../../components/layout"
import { SEO } from "../../components/seo"
import AboutTextSection from "../../components/organisms/About/AboutTextSection"
import AboutSection from "../../components/organisms/HomePage/AboutSection"
import TeamSection from "../../components/organisms/HomePage/TeamSection"

const Page = props => {
  const ACF = props.pageContext.ACF_about
  const SEO = props.pageContext.seo
  const team = ACF.zespol

  return (
    <Layout seoData={SEO} seoUrl={props.pageContext.uri} overflow="hidden">
      <AboutSection
        display="none"
        secondDisplay="none"
        content={ACF.title}
        buttonText="brief"
        buttonLink="/o-firmie"
        buttonColor="#000"
        class="about__content align-center"
        minHeight="100vh"
      />
      <AboutTextSection content={ACF.textSections} />
      <TeamSection data={team} />
    </Layout>
  )
}
export default Page

export const Head = props => {
  return (
   <SEO title={props.pageContext.seo.title} description={props.pageContext.seo.opengraphDescription} image={props.pageContext.seo?.opengraphImage?.localFile.childImageSharp.fixed.src} pathname={props.pageContext.seo.opengraphUrl} />
  )
}
